import {
  IProfile,
  VendorNames,
  useToast,
  IError,
  LoadingOverlay,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { ReactElement, useState } from 'react';

// Components.
import { EditableContent, EditableDetail } from 'components';

// Styles.
import styles from './ViewAdditionalDetails.module.scss';
import { IAccount } from 'types';
import { useUpdateComptiaIdandPearsonVueId } from 'hooks/admin/UseUpdateComptiaIdandPearsonVueId';
import { validationSchema } from './validationSchema';

interface IViewAdditionalDetails {
  profile?: IProfile;
  account?: IAccount;
  open: boolean;
}

export const ViewAdditionalDetails = (
  props: IViewAdditionalDetails,
): ReactElement => {
  const { open, profile, account } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [popToast] = useToast();
  const [updateExternalIds] = useUpdateComptiaIdandPearsonVueId(profile?.id);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comptiaId: profile?.compTIAId || '',
      pearsonVueId: profile?.externalIds?.pearsonVue || '',
    },
    onSubmit: async (values) => {
      if (account?.permissions?.changePearsonVueAndComptiaIds) {
        try {
          await updateExternalIds(values);
          setIsEditing(false);
          popToast('CompTIA Id/PearsonVue Id successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema,
  });

  return (
    <>
      {open && (
        <div className={styles.container} id="view-additional-details">
          <EditableContent
            canEdit={
              account?.permissions?.changePearsonVueAndComptiaIds ?? false
            }
            isArchived={profile?.archived ?? false}
            isEditing={isEditing}
            onCancel={() => {
              formik.resetForm();
              setIsEditing(false);
            }}
            onEdit={() => setIsEditing(true)}
            formik={formik}
            title=""
          >
            <div className={styles.columns}>
              <div className={styles.column}>
                <div className={styles.detail}>
                  <EditableDetail
                    label="Candidate ID"
                    maxLength={30}
                    name="comptiaId"
                    required
                  />
                </div>
                <div className={styles.detail}>
                  <strong className={styles.header} id="detail-internal-ids">
                    Internal IDs
                  </strong>
                  <dl
                    aria-labelledby="detail-internal-ids"
                    className={styles.descriptionlist}
                  >
                    <dt
                      className={styles.descriptionterm}
                      id="profile-id-label"
                    >
                      Profile Id
                    </dt>
                    <dd
                      aria-labelledby="profile-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.id || 'None'}
                    </dd>
                    <dt className={styles.descriptionterm} id="sso-id-label">
                      SSO Id
                    </dt>
                    <dd
                      aria-labelledby="sso-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.ssoId || 'None'}
                    </dd>
                  </dl>
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.detail}>
                  <strong className={styles.header} id="detail-external-ids">
                    External IDs
                  </strong>
                  <div
                    aria-labelledby="detail-external-ids"
                    className={styles.descriptionlist}
                  >
                    <div
                      className={styles.descriptionterm}
                      id="benchprep-id-label"
                    >
                      {VendorNames.BenchPrep}
                    </div>
                    <div
                      aria-labelledby="benchprep-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.externalIds?.benchPrep || 'None'}
                    </div>
                    <div
                      className={styles.descriptionterm}
                      id="canvas-practice-id-label"
                    >
                      {VendorNames.Canvas}
                    </div>
                    <div
                      aria-labelledby="canvas-practice-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.externalIds?.canvas || 'None'}
                    </div>
                    <div
                      className={styles.descriptionterm}
                      id="certmaster-practice-id-label"
                    >
                      {VendorNames.CertMasterPractice}
                    </div>
                    <div
                      aria-labelledby="certmaster-practice-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.externalIds?.certMasterPractice || 'None'}
                    </div>
                    <div
                      className={styles.descriptionterm}
                      id="gutenberg-id-label"
                    >
                      {VendorNames.Gutenberg}
                    </div>
                    <div
                      aria-labelledby="gutenberg-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.externalIds?.gutenberg || 'None'}
                    </div>
                    <div
                      className={styles.descriptionterm}
                      id="comptia-optimizely-id-label"
                    >
                      {VendorNames.Optimizely}
                    </div>
                    <div
                      aria-labelledby="comptia-optimizely-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.externalIds?.optimizely || 'None'}
                    </div>
                    <div
                      aria-labelledby="pearson-vue-id-label"
                      className={styles.descriptiondetail}
                    >
                      <EditableDetail
                        label="Pearson VUE"
                        maxLength={30}
                        name="pearsonVueId"
                        required
                      />
                    </div>
                    <div
                      className={styles.descriptionterm}
                      id="skillable-id-label"
                    >
                      {VendorNames.Skillable}
                    </div>
                    <div
                      aria-labelledby="skillable-id-label"
                      className={styles.descriptiondetail}
                    >
                      {profile?.externalIds?.skillable || 'None'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </EditableContent>
          <LoadingOverlay
            isOpen={formik.isSubmitting}
            text="Updating CompTIA Id / PearsonVue Id"
          />
        </div>
      )}
    </>
  );
};
